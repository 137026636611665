import React, { useState } from 'react';
import './App.css';

function AddClientToManifiest() {
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = new URLSearchParams({
      client: client,
      email: email
    });

    try {
      const res = await fetch(`/v1/add-client-to-manifiest/?${params.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!res.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await res.json();
      setResponse(result.data);
      setMessage(result.message);
    } catch (error) {
      console.error('Error adding client to manifiest:', error);
      setMessage('Error adding client to manifiest');
    }
  };

  const prettyPrintResponse = (data) => {
    return (
      <div className="response-content">
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Add Client to Manifiest</h1>
        </div>
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit">Add Client</button>
        </form>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {response && prettyPrintResponse(response)}
        </div>
      </div>
    </div>
  );
}

export default AddClientToManifiest;
