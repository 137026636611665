// CreateConfigMap.js

import React, { useState } from 'react';
import './App.css'; // Use the common CSS file

function CreateConfigMap() {
  const [client, setClient] = useState('');
  const [channelName, setChannelName] = useState('');
  const [ad_duration, setDuration] = useState('');
  const [log_level, setLevel] = useState('');
  const [reference, setReference] = useState('');
  const [region, setRegion] = useState('');
  const [configMap, setConfigMap] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      apiVersion: 'v1',
      kind: 'ConfigMap',
      metadata: {
        namespace: 'default',
      },
      data: {
        client,
        channel_name: channelName,
        ad_duration,
        log_level,
        reference,
        region
      },
    };

    try {
      const response = await fetch('/v1/create-configmap/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setConfigMap(result);
      setMessage('ConfigMap created successfully');
    } catch (error) {
      console.error('Error creating config map:', error);
      setMessage('Error creating config map');
    }
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Create a ConfigMap</h1>
        </div>
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Channel Name</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Ad Duration</label>
            <input
              type="text"
              value={ad_duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Log Level</label>
            <input
              type="text"
              value={log_level}
              onChange={(e) => setLevel(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Reference</label>
            <input
              type="text"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Region</label>
            <input
              type="text"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
            />
          </div>
          <button type="submit">Create ConfigMap</button>
        </form>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {configMap && (
            <div className="response-content">
              {JSON.stringify(configMap, null, 2)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateConfigMap;
