import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import GetPods from './GetPods';
import GetConfigMap from './GetConfigMap';
import GetConfigMaps from './GetConfigMaps';
import CreateConfigMap from './CreateConfigMap';
import UpdateConfigMap from './UpdateConfigMap';
import GetManifiest from './GetManifiest';
import GetDataFromManifiest from './GetDataFromManifiest';
import AddClientToManifiest from './AddClientToManifiest';
import AddChannelToManifiest from './AddChannelToManifiest';
import DeleteChannelFromManifiest from './DeleteChannelFromManifiest';
import DeploysApp from './DeploysApp'; // Import the new component
import './App.css';
import logo from './logo.svg';

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <Router>
      <div className="App">
        <div className="top-bar">
          <div className="top-bar-title">
            <img src={logo} className="App-logo" alt="logo" onClick={toggleSidebar} />
            <span className="orchestrator-api"> Orchestrator API</span>
          </div>
        </div>
        <Sidebar isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
        <div className={`main-content ${isSidebarCollapsed ? 'collapsed' : ''}`}>
          <Routes>
            <Route path="/get-pods" element={<GetPods />} />
            <Route path="/get-configmap" element={<GetConfigMap />} />
            <Route path="/get-configmaps" element={<GetConfigMaps />} />
            <Route path="/create-configmap" element={<CreateConfigMap />} />
            <Route path="/update-configmap" element={<UpdateConfigMap />} />
            <Route path="/get-manifiest" element={<GetManifiest />} />
            <Route path="/get-data-from-manifiest" element={<GetDataFromManifiest />} />
            <Route path="/add-client-to-manifiest" element={<AddClientToManifiest />} />
            <Route path="/add-channel-to-manifiest" element={<AddChannelToManifiest />} />
            <Route path="/deploy-app" element={<DeploysApp />} />
            <Route path="/delete-channel-from-manifiest" element={<DeleteChannelFromManifiest />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
