import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

import configmap from './configmap.svg';
import configmaps from './configmaps.svg';
import updateConfig from './updateConfig.svg';
import createConfig from './createConfigmap.svg';
import manifiest from './manifiest.svg';
import dataManifiest from './dataManifiest.svg';
import addClient from './addClient.svg';
import addChannel from './addChannel.svg';
import deploysApp from './deploysApp.svg'; // Import the new icon
import deleteChannel from './deleteChannel.svg'; // Import the new icon
import pods from './pods.svg'; // Import the new icon

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <ul>
        <li>
            {isCollapsed ? (
              <Link to="/get-pods" title='Get the pods'>
                <img src={pods} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/get-pods">1. Get All Pods</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/get-configmaps" title='Get the configmaps'>
                <img src={configmaps} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/get-configmaps">1. Get All Launchers</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/create-configmap" title='Create a configmap'>
                <img src={createConfig} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/create-configmap">2. Create Launcher</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/get-configmap" title='Get a configmap'>
                <img src={configmap} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/get-configmap">3. Get Client Launcher</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/update-configmap" title='Update a configmap'>
                <img src={updateConfig} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/update-configmap">4. Update Launcher</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/get-manifiest" title='Get manifiest'>
                <img src={manifiest} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/get-manifiest">5. Get Manifiest</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/get-data-from-manifiest" title='Get data from manifiest'>
                <img src={dataManifiest} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/get-data-from-manifiest">6. Get Data from Manifiest</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/add-client-to-manifiest" title='Add client to manifiest'>
                <img src={addClient} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/add-client-to-manifiest">7. Add Client to Manifiest</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/add-channel-to-manifiest" title='Add channel to manifiest'>
                <img src={addChannel} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/add-channel-to-manifiest">8. Add Channel to Manifiest</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/delete-channel-from-manifiest" title='Delete Channel'>
                <img src={deleteChannel} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/delete-channel-from-manifiest">9. Delete Channel</Link>
            )}
          </li>
          <li>
            {isCollapsed ? (
              <Link to="/deploy-app" title='Deploys App'>
                <img src={deploysApp} alt="Collapsed Logo" className="collapsed-logo" />
              </Link>
            ) : (
              <Link to="/deploy-app">10. Deploys App</Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
