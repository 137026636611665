import React, { useState } from 'react';
import './App.css'; // Use the common CSS file

function GetConfigMap() {
  const [client, setClient] = useState('');
  const [channelName, setChannelName] = useState('');
  const [configMap, setConfigMap] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`/v1/get-configmap/?client=${client}&channel_name=${channelName}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setConfigMap(result);
    } catch (error) {
      console.error('Error fetching config map:', error);
      setConfigMap('Error fetching config map');
    }
  };

  const prettyPrintJson = (json) => {
    return (
      <div>
        <p><strong>Message:</strong> {json.message}</p>
        <p><strong>Data:</strong></p>
        <ul>
          <li><strong>Channel Name:</strong> {json.data.channel_name}</li>
          <li><strong>Client:</strong> {json.data.client}</li>
          <li><strong>Ad Duration:</strong> {json.data.ad_duration}</li>
          <li><strong>Log Level:</strong> {json.data.log_level}</li>
          <li><strong>Reference:</strong> {json.data.reference}</li>
        </ul>
      </div>
    );
  };

  return (
    <div className="main-content">
      <div className="top-bar-title">
        <h1>Get a Client</h1>
      </div>
      <div className="config-form-container">
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Channel Name</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              required
            />
          </div>
          <button type="submit">Get ConfigMap</button>
        </form>
        <div className="response-container">
          {configMap && (
            <div className="response-message">
              {prettyPrintJson(configMap)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GetConfigMap;
