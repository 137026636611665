import React, { useState } from 'react';
import './App.css';

function DeleteChannelFromManifiest() {
  const [client, setClient] = useState('');
  const [channelName, setChannelName] = useState('');
  const [response, setResponse] = useState(null);
  const [message, setMessage] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = new URLSearchParams({
      client: client,
      channel_name: channelName
    });

    try {
      const res = await fetch(`/v1/delete-channel/?${params.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!res.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await res.json();
      setResponse(result.data);
      setMessage(result.message);
    } catch (error) {
      console.error('Error deleting channel from manifiest:', error);
      setMessage('Error deleting channel from manifiest');
    }
  };

  const prettyPrintResponse = (data) => {
    return (
      <div className="response-content">
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Delete Channel from Manifiest</h1>
        </div>
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Channel Name</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              required
            />
          </div>
          <button type="submit">Delete Channel</button>
        </form>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {response && prettyPrintResponse(response)}
        </div>
      </div>
    </div>
  );
}

export default DeleteChannelFromManifiest;
