import React, { useState } from 'react';
import './App.css'; // Use the common CSS file

function UpdateConfigMap() {
  const [client, setClient] = useState('');
  const [channelName, setChannelName] = useState('');
  const [ad_duration, setDuration] = useState('');
  const [log_level, setLevel] = useState('');
  const [reference, setReference] = useState('');
  const [data, setData] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      apiVersion: 'v1',
      kind: 'ConfigMap',
      metadata: {
        namespace: 'default',
      },
      data: {
        client,
        channel_name: channelName,
        ad_duration,
        log_level,
        reference,
      },
    };

    try {
      const response = await fetch('/v1/update-configmap/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();
      setData(result.data);
    } catch (error) {
      console.error('Error updating config map:', error);
      setData(null);
    }
  };

  const prettyPrintData = (data) => {
    return (
      <div className="config-map">
        <ul>
          <li><strong>Channel Name:</strong> {data.channel_name}</li>
          <li><strong>Client:</strong> {data.client}</li>
          <li><strong>Ad Duration:</strong> {data.ad_duration}</li>
          <li><strong>Log Level:</strong> {data.log_level}</li>
          <li><strong>Reference:</strong> {data.reference}</li>
        </ul>
      </div>
    );
  };

  return (
    <div className="main-content">
      <div className="top-bar-title">
        <h1>Update a ConfigMap</h1>
      </div>
      <div className="config-form-container">
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Channel Name</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Ad Duration</label>
            <input
              type="text"
              value={ad_duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Log Level</label>
            <input
              type="text"
              value={log_level}
              onChange={(e) => setLevel(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Reference</label>
            <input
              type="text"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              required
            />
          </div>
          <button type="submit">Update ConfigMap</button>
        </form>
        <div className="response-container">
          {data && prettyPrintData(data)}
        </div>
      </div>
    </div>
  );
}

export default UpdateConfigMap;
