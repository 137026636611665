import React, { useState } from 'react';
import './App.css';

function GetDataFromManifiest() {
  const [client, setClient] = useState('');
  const [channelName, setChannelName] = useState('');
  const [data, setData] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = new URLSearchParams({
      client: client,
      channel_name: channelName
    });

    try {
      const response = await fetch(`/v1/get-data-from-manifiest/?${params.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result.data);
      setMessage(result.message);
    } catch (error) {
      console.error('Error fetching data from manifiest:', error);
      setMessage('Error fetching data from manifiest');
    }
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Get Data from Manifiest</h1>
        </div>
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Channel Name</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
            />
          </div>
          <button type="submit">Get Data</button>
        </form>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {data && (
            <div className="response-content">
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GetDataFromManifiest;
