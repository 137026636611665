import React, { useState, useEffect } from 'react';
import './App.css'; // Use the existing CSS file for all forms

function GetConfigMaps() {
  const [configMaps, setConfigMaps] = useState({});
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetch('/v1/get-configmaps/')
      .then(response => response.json())
      .then(data => {
        setConfigMaps(data.data);
        setMessage(data.message);
      })
      .catch(error => {
        console.error('Error fetching config maps:', error);
        setMessage('Error fetching config maps');
      });
  }, []);

  const prettyPrintConfigMaps = (maps) => {
    return Object.keys(maps).map((key) => (
      <div key={key} className="config-map">
        <h3>{key}</h3>
        <ul>
          <li><strong>Channel Name:</strong> {maps[key].channel_name}</li>
          <li><strong>Client:</strong> {maps[key].client}</li>
          <li><strong>Ad Duration:</strong> {maps[key].ad_duration}</li>
          <li><strong>Log Level:</strong> {maps[key].log_level}</li>
          <li><strong>Reference:</strong> {maps[key].reference}</li>
        </ul>
      </div>
    ));
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Get the ConfigMaps</h1>
        </div>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {configMaps && prettyPrintConfigMaps(configMaps)}
        </div>
      </div>
    </div>
  );
}

export default GetConfigMaps;
