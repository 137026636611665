// GetManifiest.js

import React, { useState, useEffect } from 'react';
import './App.css';

function GetManifiest() {
  const [manifiest, setManifiest] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetch('/v1/get-manifiest/')
      .then(response => response.json())
      .then(data => {
        setManifiest(data.data);
        setMessage(data.message);
      })
      .catch(error => {
        console.error('Error fetching manifiest:', error);
        setMessage('Error fetching manifiest');
      });
  }, []);

  const prettyPrintManifiest = (manifiest) => {
    return (
      <div className="manifiest">
        <pre>{JSON.stringify(manifiest, null, 2)}</pre>
      </div>
    );
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Get Manifiest</h1>
        </div>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {manifiest && prettyPrintManifiest(manifiest)}
        </div>
      </div>
    </div>
  );
}

export default GetManifiest;
