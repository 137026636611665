import React, { useState } from 'react';
import './App.css'; // Use the common CSS file

function DeploysApp() {
  const [region, setRegion] = useState('');
  const [client, setClient] = useState('');
  const [channelName, setChannelName] = useState('');
  const [deployResponse, setDeployResponse] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`/v1/deploys-app/?client=${client}&channel_name=${channelName}&region=${region}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setDeployResponse(result);
    } catch (error) {
      console.error('Error deploying app:', error);
      setDeployResponse({ message: 'Error deploying app', client, channel_name: channelName });
    }
  };

  const prettyPrintJson = (json) => {
    return (
      <div>
        <p><strong>Message:</strong> {json.message}</p>
        <p><strong>Client:</strong> {json.client}</p>
        <p><strong>Channel Name:</strong> {json.channel_name}</p>
        <p><strong>Region:</strong> {json.region}</p>
        {json.URL && (
          <p><strong>URL:</strong> <a href={json.URL} target="_blank" rel="noopener noreferrer" className="custom-link">{json.URL}</a></p>
        )}
      </div>
    );
  };


  return (
    <div className="main-content">
      <div className="top-bar-title">
        <h1>Deploy an App</h1>
      </div>
      <div className="config-form-container">
        <form onSubmit={handleSubmit} className="config-form">
          <div>
            <label>Client</label>
            <input
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Channel Name</label>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Region</label>
            <input
              type="text"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
            />
          </div>
          <button type="submit">Deploy App</button>
        </form>
        <div className="response-container">
          {deployResponse && (
            <div className="response-message">
              {prettyPrintJson(deployResponse)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeploysApp;
