import React, { useState, useEffect } from 'react';
import './App.css'; // Use the existing CSS file for all forms

function GetPods() {
  const [pods, setPods] = useState({});
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetch('/v1/get-pods/')
      .then(response => response.json())
      .then(data => {
        setPods(data.data);
        setMessage(data.message);
      })
      .catch(error => {
        console.error('Error fetching pods:', error);
        setMessage('Error fetching pods');
      });
  }, []);

  const prettyPrintPods = (pods) => {
    return Object.keys(pods).map((podName) => (
      <div key={podName} className="pod">
        <h3>{podName}</h3>
        <ul>
          <li><strong>Status:</strong> {pods[podName]}</li>
        </ul>
      </div>
    ));
  };

  return (
    <div className="main-content">
      <div className="config-form-container">
        <div className="form-title">
          <h1>Get the Pods</h1>
        </div>
        <div className="response-container">
          {message && <div className="response-message">{message}</div>}
          {pods && prettyPrintPods(pods)}
        </div>
      </div>
    </div>
  );
}

export default GetPods;
